import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";

const StyledParagraph = styled.p`
    padding-top: 87px;
    margin: 40px 0px;
    font-size: 0.8rem;
`;
const ActivitesPage = () => (
  <Layout>
    <SEO title="Zakres Działalności" />
    <Hero title="Zakres Działalności" />
    <StyledParagraph>
        HLS to dynamiczne połączenie logistyki ekonomicznej i logistyki społecznej: wyższego poziomu bezpieczeństwa pacjenta, przy jednoczesnej racjonalizacji wybranych procesów szpitalnych. Bezpieczeństwo i wysoki poziom obsługi pacjenta jest podstawowym celem działania szpitala i wpisuje się w nurt logistyki społecznej, dzięki zastosowaniu sprawdzonych w innych branżach rozwiązań (GS1, EDI, ADC) i nowoczesnych technologii (czytniki kodów paskowych, drukarki kodów, mobilne kolektory danych) możliwe stało się osiągnięcie daleko idących korzyści finansowych, a tym samym integracja celu społecznego z celem gospodarczym / biznesowym.
    </StyledParagraph>
  </Layout>
)

export default ActivitesPage
